import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'create-jump/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./create-jump/create-jump.module').then( m => m.CreateJumpPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'manage-account',
    loadChildren: () => import('./manage-account/manage-account.module').then( m => m.ManageAccountPageModule)
  },
  {
    path: 'podcast/:id',
    loadChildren: () => import('./podcast-page/podcast-page.module').then( m => m.PodcastPagePageModule)
  },
  {
    path: 'podcast-ii/:indexid',
    loadChildren: () => import('./podcast-page/podcast-page.module').then( m => m.PodcastPagePageModule)
  },
  {
    path: 'jumplink/:keyword',
    loadChildren: () => import('./search-jumplink/search-jumplink.module').then( m => m.SearchJumplinkPageModule)
  },
  {
    path: 'jumplink/',
    loadChildren: () => import('./search-jumplink/search-jumplink.module').then( m => m.SearchJumplinkPageModule)
  },
  {
    path: 'add-podcast',
    canActivate: [AuthGuard],
    loadChildren: () => import('./add-podcast/add-podcast.module').then( m => m.AddPodcastPageModule)
  },
  {
    path: 'search-for-podcast/:search-term',
    loadChildren: () => import('./search-for-podcast/search-for-podcast.module').then( m => m.SearchForPodcastPageModule)
  },
  {
    path: 'search-for-podcast',
    loadChildren: () => import('./search-for-podcast/search-for-podcast.module').then( m => m.SearchForPodcastPageModule)
  },
  {
    path: 'jump-details/:id',
    loadChildren: () => import('./jump-details/jump-details.module').then( m => m.JumpDetailsPageModule)
  },
  {
    path: 'jump-details/:id/edit',
    loadChildren: () => import('./edit-jumplink/edit-jumplink.module').then( m => m.EditJumplinkPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
