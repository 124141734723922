import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Events } from './services/events.service';

import { version } from './globals';

import { firebaseConfig } from './globals';

import { firebase } from '@firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  version : string = version;
  public topPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
    }
  ];

  public bottomPages = [
    {
      title: 'About JumpLink',
      url: 'https://jump.link',
      icon: 'list',
    }
  ];

  isAuthenticated: boolean;
  user: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    private alertCtrl: AlertController,
    private event: Events,
    private storage: Storage,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      firebase.initializeApp(firebaseConfig);

      if(this.auth.isAuthenticated){
        this.auth.activeUser().subscribe(data => {
          this.user = data;
        },
        err => {
          this.showAlert('Login Error', 'Please login again.');
        }
      )
      }
      this.listenForLoginAndUpdateUser();
      this.splashScreen.hide();
    });
  }

  async showAlert(header: string, message: string){
    let alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: ['OK']
    })
    await alert.present()
  }

  listenForLoginAndUpdateUser(){
    this.event.subscribe('refreshUser', data => {
      if(data){
        this.getUser();
      }
    })
  }

  getUser(){
    this.storage.get('activeUser').then(data => {
      this.user = data;
    },
    err => {
      this.user = null
    }
    )
  }

  logout(){
    this.auth.logout();
  }
}
