'use strict';

export var version = '0.0.8';

// development
// export var base_url = 'http://localhost:8000/jl/';
// export var endpoint = 'http://localhost:8000/api/v1';
// export var client_id = 'x3GTqDY12OMChqv5EMTof6aZv7yTkkfNkspiLHKU';
// export var client_secret = 'BNTqJE7dJX3JihQwOicJK8BULBuN1Jfux5fX2fZk3U0x3j9JARIHxzdgNeHMxlC21GtFqKurxizwJuuwvIv0RoNDGCyeJol8O5qj8TjnfMLxkIu9124ZhipXclHk1VRI';

// production
export var base_url = 'https://info.jump.link/jl/';
export var endpoint = 'https://info.jump.link/api/v1';
export var client_id = 'NBeAt0DSGsdZ9vsq8mrkRfcHv6xD9UbZI7sGxx0c';
export var client_secret = 'LyPyaLDgtifxjdgRzicMVOfKGHiqdYGNyqsZnkqNHrkmhAy6XPHR60H5HFKwEwduwffImVPOMLIUCi3Iy8kSAZAfw66NxslJ3qW7xzobZuGtdKSilu1UQPfWDqwxkMqU';


export var firebaseConfig = {
  apiKey: "AIzaSyAkwBLJWvibkTfb7k9wItTPynfEbq28sWU",
  authDomain: "jumplink-baafe.firebaseapp.com",
  databaseURL: "https://jumplink-baafe.firebaseio.com",
  projectId: "jumplink-baafe",
  storageBucket: "jumplink-baafe.appspot.com",
  messagingSenderId: "213597560348",
  appId: "1:213597560348:web:ab7685a4a8e5977452c9d9",
  measurementId: "G-T53F7GECWV"
};


export var listenNotesApiBase = 'https://listen-api.listennotes.com/api/v2/'
