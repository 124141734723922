import { Injectable } from '@angular/core';
import { endpoint, client_id, client_secret} from "../../app/globals";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { Events } from '../services/events.service';

export class User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  membership_level: string;
  is_staff: Boolean;
  constructor(id: number, email: string,first_name: string, last_name: string, full_name: string, username: string, membership_level: string, is_staff: Boolean) {
    this.id = id;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.full_name = full_name;
    this.username = username;
    this.membership_level = membership_level;
    this.is_staff = is_staff
  }
}

// Developent
const authConfig = {
  client_id: client_id,
  grant_type: 'password',
  client_secret: client_secret,
  username: '',
  password: ''
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  headers: any;

  currentUser: User;
  public isAuthenticated: boolean = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(public http: HttpClient, private storage: Storage, private router: Router, private navCtrl: NavController, private event: Events) {
    let token = localStorage.getItem('access_token');
    this.isAuthenticated = !!token;
    if(this.isAuthenticated){
      this.activeUser().subscribe(data => {

      },
      err => {
        this.logout();
      })
    }
  }

  publishGlobalReset(){
    this.event.publish('refreshUser', true);
  }

  login(username: string, password: string): Observable <boolean> {
    let headers = {
      'Content-Type':'application/json'
    };
    authConfig['username'] = username;
    authConfig['password'] = password;
    let response = this.http.post(endpoint + '/oauth/token/', authConfig, {headers:headers} )
    return response
    .pipe(map((res: any) => {
      if (res.access_token) {
        // Find a way around storing this variable in two places
        localStorage.setItem('access_token', res.access_token);
        this.storage.set('access_token', res.access_token);
        this.isAuthenticated = true;
        this.activeUser();

        // this.currentUser = new User(username, res.access_token);

        this.router.navigate(['/home']);
        return true;
      }
      else{
        return false;
      }
    }))

  }

  activeUser(){
    let headers = {
      'Content-Type':'application/json',
      'Authorization': this.getTokenForHeader()
    };
    let response = this.http.get( endpoint + '/accounts/active/', {headers:headers});
    return response
    .pipe(map((res: any) => {
      this.setCurrentUser(res);
      return this.currentUser;
    }))
  }

  setCurrentUser(userObject: Object){
    this.currentUser = new User(userObject['id'], userObject['email'], userObject['first_name'], userObject['last_name'], userObject['full_name'], userObject['username'], userObject['membership_level'], userObject['is_staff']);
    this.storage.set('activeUser', this.currentUser).then(data => {
      this.publishGlobalReset();
    })
  }

  returnCurrentUser(){
    return this.currentUser;
  }

  getTokenForHeader() {
    let token = 'Bearer ' + localStorage.getItem('access_token');
    return token;
  }

  convertTwitter(token, token_secret, backend){

    let thirdpartytoken = 'oauth_token=' + token + '&oauth_token_secret=' + token_secret;

    const convertConfig = {
      client_id: authConfig.client_id,
      grant_type: 'convert_token',
      backend: 'twitter',
      token: thirdpartytoken
    };

    let headers = {
      'Content-Type':'application/json'
    };

    let response = this.http.post( endpoint + '/oauth/convert-token/', convertConfig, {headers:headers});
    return response
    .pipe(map((res: any) => {
      localStorage.setItem('access_token', res.access_token);
      this.storage.set('access_token', res.access_token);
      this.isAuthenticated = true;
      this.router.navigate(['/home']);
    }))
  }

  convertGoogle(token){
    const convertConfig = {
      client_id: authConfig.client_id,
      client_secret: authConfig.client_secret,
      grant_type: 'convert_token',
      backend: 'google-oauth2',
      token: token
    };

    let headers = {
      'Content-Type':'application/json'
    };

    let response = this.http.post( endpoint + '/oauth/convert-token/', convertConfig, {headers:headers});
    return response
    .pipe(map((res: any) => {
      localStorage.setItem('access_token', res.access_token);
      this.storage.set('access_token', res.access_token);
      this.isAuthenticated = true;
      this.router.navigate(['/home']);
    }))
  }

  logout(){
    localStorage.removeItem('access_token');
    this.storage.remove('access_token');
    this.storage.clear();
    this.currentUser = null;
    this.isAuthenticated = false;
    this.publishGlobalReset();
  }


}
